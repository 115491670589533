<template>
  <b-row>
    <b-col cols="6">
      <b-card no-body>
        <b-card-header>
          <b-cart-title><span style="font-weight: bold; font-size: 1.2em"><feather-icon icon="UserIcon" size="26" /> {{ id ? 'Sửa Nhóm' : 'Tạo Nhóm Mới'}}</span></b-cart-title>
          <b-card-sub-title>
            <b-button variant="success" @click="validationForm"><feather-icon icon="SaveIcon" /> {{ id ? 'Cập Nhật' : 'Tạo Mới'}}</b-button>
          </b-card-sub-title>
        </b-card-header>
        <b-card-body>
          <validation-observer ref="createGroup">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Tên Nhóm" label-cols="3">
                  <b-form-input />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Quyền Trưởng nhóm" label-cols="3">
                  <vue-select />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BCardBody, BCardTitle, BCardSubTitle, BCardHeader, BFormInput, BFormDatepicker, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import VueSelect from 'vue-select'

import Role from './components/Role.vue'
export default {
  props: {
    id: {
      type: Number,
      default: null,
    }
  },
  components: {
    VueSelect,
    BFormGroup,
    BFormInput,
    Role,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BRow,
    BCol,
    BButton,
    BCard,
    FeatherIcon,
},
  methods: {
    validationForm() {
      console.log('submit')
    },
  },
  data() {
    return {
      form: {
        staff_name: '',
        staff_password: '',
        phone: null,
        birthday: null,
        type: '',
        gender: null,
        district: null,
        districts: null,
        province: null,
        ward: null,
        country: 'VN',
        roles: [{}],
        groups: [{}],
      },
      required,
    }
  },
}
</script>

